<template>
  <div class="book_data">
    <img :src="detail.coverAddress" />
    <div> 
      <div style="min-height: 230px;">
        <h3>{{ detail.name }}</h3>
        <div class="list">
          <p v-for="item in data" :key="item.key">
            <span>{{ item.title }}</span>
            <span>{{ detail[item.key] }}{{ item.key == 'price' ? '元' : '' }}</span>
          </p>
        </div>
      </div>
      <div>
        <el-button v-if="detail.buyPattern == 0 || detail.buyPattern == 2" plain type="primary" color="#0072EF" @click="visible = true"><svg-icon icon-class="shopping-cart"></svg-icon>标院购书</el-button>
        <el-button v-if="detail.buyPattern == 1 || detail.buyPattern == 2" type="danger" color="#F02818" plain @click="eventClick"><svg-icon icon-class="shopping-cart"></svg-icon>京东购书</el-button>
        <el-button v-if="!detail.buyPattern && detail.buyPattern != 0" type="info">暂不销售</el-button>
      </div>
    </div>
    <BuyModal :visible="visible" @close="visible = false"></BuyModal>
  </div>
</template>

<script>
import BuyModal from './BuyModal'
export default {
  props: ['detail'],
  components: {
    BuyModal
  },
  data() {
    return {
      visible: false,
      data: [
        { title: '作者', key: 'author' },
        { title: '出版社', key: 'publisher' },
        { title: '出版日期', key: 'publishedDate' },
        { title: 'ISBN', key: 'isbn' },
        { title: '定价', key: 'price' },
      ]
    }
  },
  
  methods: {
    eventClick(type = null) {
      if(type == 'edit') this.$router.push('/editBook/' + this.detail.id)
      else window.open(this.detail.buyUrl)
    },
  }
}
</script>

<style lang='scss'>
  .book_data {
    display: flex;

    & > img {
      width: 200px;
      height: 280px;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      margin-right: 25px;
    }

    & > div {
      width: 909px;

      & > div {
        padding: 5px 10px;

        & > h3 {
          line-height: 46px;
          font-size: 32px;
          color: #303133;
          margin-bottom: 15px;
        }

        .list {
          & > p {
            display: flex;
            margin-bottom: 10px;

            & > span {
              &:nth-child(1) {
                width: 60px;
                font-size: 14px;
                color: #999999;
                line-height: 20px;
                display: block;
                flex-shrink: 0;
                margin-right: 15px;
              }

              &:nth-child(2) {
                width: 760px;
                font-size: 14px;
                color: #333333;
                line-height: 20px;
                display: block;
                flex-shrink: 0;
              }
            }
          }
        }
      }

      .el-button {
        height: 40px;
        width: 121px;
        font-size: 14px;

        .svg-icon {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
    }
  }
</style>