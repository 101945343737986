<template>
  <div id="bookDetail" class="common_detail" v-wechat-title="detail.name + ' - 社会团体标准化专业人员公共服务平台'">
    <CommonBread :dataList="dataList"></CommonBread>
    <div class="content">
      <BookData :detail="detail"></BookData>
      <el-divider />
      <div class="w-e-text" v-html="detail.content"></div>
      <!-- <HasTitleShow :data="data" :detail="detail"></HasTitleShow> -->
    </div>
  </div>
</template>

<script>
import CommonBread from '@/components/CommonBread'
// import HasTitleShow from '@/components/HasTitleShow'
import BookData from './components/BookData'
export default {
  components: {
    CommonBread,
    // HasTitleShow,
    BookData
  },
  data() {
    return {
      dataList: [
        { title: '书籍', url: '/knowledgeBase/BOOK' },
        { title: '详情' },
      ],
      detail: {},
      data: []
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    showPage() {
      this.$API.BookDetail({
        id: parseInt(this.$route.params.id)
      }).then(res => {
        if(res.data.code == 200) {
          res.data.data.publishedDate = res.data.data.publishedDate.split(' ')[0]
          res.data.data.coverAddress = res.data.data.coverAddress ? this.baseUrl + res.data.data.coverAddress : require('@/assets/img/nocover.png')
          this.detail = res.data.data
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  #bookDetail {
    width: 1180px;
    margin: 0 auto 30px;

    .content {
      background: #ffffff;
      border: 1px solid #ebebeb;
      border-radius: 10px;
      padding: 40px 50px;

      .el-divider {
        margin: 40px 0 30px;
      }
    }
  }
</style>